import { gql } from "graphql-tag";

export const AUTH_USER_FIELDS_FRAGMENT = gql`
  fragment AuthUserFieldsFragment on User {
    id
    first_name
    last_name
    email
    role
    created_at
  }
`;

export const AUTH_USER_QUERY = gql`
  query GetAuthUser {
    authUser {
      id
      first_name
      last_name
      email
    }
  }
`;

export const UPDATE_AUTH_USER_MUTATION = gql`
  mutation UpdateAuthUser($input: UpdateAuthUserInput!) {
    updateAuthUser(input: $input) {
      success
    }
  }
`;

export const UPDATE_AUTH_USER_PASSWORD_MUTATION = gql`
  mutation UpdateAuthUserPassword($input: UpdateAuthUserPasswordInput!) {
    updateAuthUserPassword(input: $input) {
      success
    }
  }
`;

export const ME_QUERY = gql`
  query Me {
    me {
      id
      first_name
      last_name
      email
      avatar
    }
  }
`;
