<template>
  <div>
    <h4>Requests</h4>

    <div class="row" style="float: right">
      <el-dropdown
        @command="handleBulkAction"
        size="medium"
        split-button
        type="primary"
      >
        Bulk Action
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="downloadForm8821Zip"
              >Download Form 8821 Zip</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <br clear="all" />
    <div style="margin-top: 30px">
      <el-table
        :data="tableData"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>

        <el-table-column prop="created_at" label="Created At" width="180" />
        <el-table-column label="Status" width="200">
          <template #default="{ row }">
            <el-tag type="warning">{{ row.status }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="info" label="Info" />
        <el-table-column label="" width="120">
          <template #default="{ row }">
            <el-button type="text" @click="viewRequest(row.id)">
              <i class="fa fa-eye"></i>&nbsp;View
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <br />

    <div v-if="paginatorInfo">
      <el-pagination
        :page-size="paginatorInfo.per_page"
        :current_page="paginatorInfo.current_page"
        layout="prev, pager, next"
        :total="paginatorInfo.total"
        @change="handlePageChange"
      ></el-pagination>
    </div>

    <br />
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import apolloClient from "@/services/apollo-client";
import { TAX_RECORD_REQUESTS_QUERY } from "@/graphql/tax-record-requests.js";
import { GENERATE_DOWNLOAD_FORM_8821_TOKEN_FOR_VERIFIER } from "@/graphql/verifiers.js";
import { taxRecordRequestMixin } from "@/mixins/tax-record-request.js";

export default {
  // mixins: [taxRecordRequestMixin],
  setup() {
    const route = useRoute();
    const router = useRouter();
    const tableData = ref([]);
    const { formatStatusText } = taxRecordRequestMixin();

    const currentPage = ref(1);
    const paginatorInfo = ref({
      total: 0,
      per_page: 0,
      current_page: 1,
    });

    const statusMapping = {
      assigned: "ASSIGNED_TO_VERIFIER",
      declined: "DECLINED_BY_VERIFIER",
      "processing-error": "PROCESSING_ERROR",
      processing: "PROCESSING",
      completed: "COMPLETED",
    };

    const selectedRows = ref([]);

    const viewRequest = (id) => {
      router.push({ name: "TaxRecordRequestPage", params: { id } });
    };

    const handleSelectionChange = (val) => {
      selectedRows.value = val;
    };

    const handlePageChange = (newPage) => {
      const routeStatus = route.params.status;
      const mappedStatus = statusMapping[routeStatus];

      currentPage.value = newPage;
      fetchRequests(mappedStatus, newPage);
    };

    const handleBulkAction = async (actionName) => {
      console.log(`Performing ${actionName} on`, selectedRows.value);
      let ids = [];
      for (let i = 0; i < selectedRows.value.length; i++) {
        ids.push(selectedRows.value[i].id);
      }

      // Assuming 'downloadForm8821Zip' is the action to generate download tokens and download files
      if (actionName === "downloadForm8821Zip") {
        try {
          const response = await apolloClient.query({
            query: GENERATE_DOWNLOAD_FORM_8821_TOKEN_FOR_VERIFIER,
          });

          const token = response.data.generateDownloadForm8821TokenForVerifier;
          const baseUrl = process.env.VUE_APP_API_URL;
          const idsParam = ids.join(",");

          // Step 2: Use the token to make the GET request
          const downloadUrl =
            baseUrl +
            `v1/verifiers/forms/8821/download/zip?token=${token}&requestIds=${idsParam}`;
          window.location.href = downloadUrl;
        } catch (error) {
          console.error("Error generating download for request ID");
          // Handle errors (e.g., display a notification)
        }
      }
    };

    const fetchRequests = async (status, page) => {
      try {
        const variables = {
          status: status,
          page: page,
        };

        const { data } = await apolloClient.query({
          query: TAX_RECORD_REQUESTS_QUERY,
          variables: variables,
          fetchPolicy: "network-only",
        });

        tableData.value = data.verifierTaxRecordRequests.data.map(
          (request) => ({
            id: request.id,
            created_at: request.created_at, // Adjust these fields based on the actual query response
            status: formatStatusText(request.status),
            data: request.data,
            info: `${request.data.legal_name}, ${request.data.city} (${request.data.state})`,
          }),
        );

        paginatorInfo.value = data.verifierTaxRecordRequests.paginator_info;
      } catch (error) {
        // Handle error state
        console.error(error);
      }
    };

    watch(
      () => route.params.status,
      (routeStatus) => {
        const mappedStatus = statusMapping[routeStatus];
        if (mappedStatus) {
          fetchRequests(mappedStatus, currentPage.value);
        } else {
          console.error("Invalid status in URL:", routeStatus);
          // Handle the invalid status case
        }
      },
    );

    onMounted(async () => {
      const routeStatus = route.params.status;
      const mappedStatus = statusMapping[routeStatus];

      if (mappedStatus) {
        await fetchRequests(mappedStatus, currentPage.value);
      } else {
        console.error("Invalid status in URL:", routeStatus);
        // Handle the invalid status case
      }
    });

    return {
      tableData,
      paginatorInfo,
      currentPage,
      viewRequest,
      handlePageChange,
      handleSelectionChange,
      handleBulkAction,
    };
  },
};
</script>
