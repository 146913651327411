<template>
  <center><Logo width="250px" /></center>
  <div class="text-center" style="background-color: #f8f9fa; min-height: 100vh">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div
            class="bg-white shadow-sm rounded"
            style="margin-top: 200px; padding: 40px; font-size: 18px"
          >
            <!-- Display success or error message -->
            <div v-if="emailVerification">
              <p v-if="emailVerification.status">
                Email verification successful, you can now
                <router-link to="/login">login</router-link> to your account
              </p>
              <p v-else>
                Email verification failed. Please try again or contact support.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import apolloClient from "@/services/apollo-client";
import { VERIFY_EMAIL_MUTATION } from "@/graphql/auth.js";
import { useRoute } from "vue-router";
import Logo from "@/components/Logo.vue";

export default {
  components: {
    Logo,
  },
  setup() {
    const route = useRoute();
    const emailVerification = ref({
      status: false,
    });

    const verifyEmail = async () => {
      console.log({
        userId: route.params.userId,
        hash: route.params.token,
        expires: parseInt(route.query.expires, 10),
        signature: route.query.signature,
      });
      try {
        const { data } = await apolloClient.mutate({
          mutation: VERIFY_EMAIL_MUTATION,
          variables: {
            input: {
              userId: route.params.userId,
              hash: route.params.token,
              expires: parseInt(route.query.expires, 10),
              signature: route.query.signature,
            },
          },
        });

        if (data.verifyEmail.success) {
          emailVerification.value.status = true;
        } else {
          emailVerification.value.status = false;
        }

        console.log(data);
      } catch (error) {
        console.error("Verification error:", error);
        emailVerification.value.status = false;
      }
    };

    onMounted(() => {
      verifyEmail();
    });

    return {
      emailVerification,
    };
  },
};
</script>
