<template>
  <div>
    <h4>Settings</h4>
    <p>Update your user account settings below</p>

    <el-form :model="form" label-position="top">
      <el-form-item label="Verifier Status">
        <el-select
          v-model="form.status"
          placeholder="Select status"
          style="width: 240px"
        >
          <el-option
            v-for="(status, name) in verifierStatuses"
            :key="status"
            :label="name"
            :value="status"
            :disabled="status === 'NEW'"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Max number of pending requests to assign">
        <el-input-number
          v-model.number="form.max_tax_record_requests"
          :min="1"
          :max="100"
        ></el-input-number>
      </el-form-item>
      <br />
      <el-button type="primary" @click="submitForm">Submit</el-button>
    </el-form>
  </div>
</template>

<script>
import { ref, watchEffect, onMounted } from "vue"; // Make sure to import ref
import {
  UPDATE_VERIFIER_SETTINGS_MUTATION,
  VERIFIER_SETTINGS_QUERY,
} from "@/graphql/verifier-settings.js";
import {} from "@/graphql/verifier-settings.js";

import {
  UPDATE_AUTH_VERIFIER_STATUS_MUTATION,
  AUTH_VERIFIER_STATUS_QUERY,
} from "@/graphql/verifiers.js";

import apolloClient from "@/services/apollo-client";
import { ElNotification } from "element-plus";

export default {
  setup() {
    const loading = ref(false);

    const form = ref({
      max_tax_record_requests: "",
      status: "",
    });

    const verifierStatuses = ref({
      New: "NEW",
      Pending: "PENDING",
      Active: "ACTIVE",
      Inactive: "INACTIVE",
      Busy: "BUSY",
      Offline: "OFFLINE",
      "On Leave": "ON_LEAVE",
    });

    const currentVerifierStatus = ref("");

    const fetch = async () => {
      loading.value = true; // Start loading

      try {
        const verifierSettingsResponse = await apolloClient.query({
          query: VERIFIER_SETTINGS_QUERY,
          fetchPolicy: "network-only",
        });

        const verifierStatusResponse = await apolloClient.query({
          query: AUTH_VERIFIER_STATUS_QUERY,
          fetchPolicy: "network-only",
        });

        const setting = verifierSettingsResponse.data.verifierSettings.find(
          (s) => s.key === "max_tax_record_requests",
        );
        if (setting) {
          // Make sure to parse the value as a number if it's expected to be numeric
          form.value.max_tax_record_requests = parseInt(setting.value, 10);
        }

        const verifierStatus = verifierStatusResponse.data.authVerifierStatus;
        console.log("===========");
        console.log(verifierStatus);

        if (verifierStatus) {
          form.value.status = verifierStatus;
        }
      } catch (error) {
        console.error(error); // Handle error state
      } finally {
        loading.value = false; // End loading
      }
    };

    const submitForm = async () => {
      loading.value = true; // Start loading

      try {
        await apolloClient.mutate({
          mutation: UPDATE_VERIFIER_SETTINGS_MUTATION,
          variables: {
            input: {
              max_tax_record_requests: String(
                form.value.max_tax_record_requests,
              ),
            },
          },
        });

        await apolloClient.mutate({
          mutation: UPDATE_AUTH_VERIFIER_STATUS_MUTATION,
          variables: {
            input: {
              status: String(form.value.status),
            },
          },
        });

        ElNotification({
          title: "Success",
          message: "This update was successful",
          type: "success",
        });
      } catch (error) {
        console.log("======== ERROR ========");
        console.error(error); // Handle error state

        ElNotification({
          title: "Error",
          message: "An unexpected error occurred",
          type: "error",
        });
      } finally {
        loading.value = false; // End loading
      }
    };

    watchEffect(() => {});

    onMounted(async () => {
      await fetch();
    });

    return {
      form,
      verifierStatuses,
      currentVerifierStatus,
      submitForm,
    };
  },
};
</script>
