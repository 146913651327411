import LoginPageVue from './LoginPage.vue';
<template>
  <div>
    <h4>Request #{{ request.id }}</h4>
    <br />
    <div class="request-details" style="padding: 30px">
      <div class="row">
        <div class="col-md-6">
          <table class="table">
            <tbody>
              <tr>
                <th scope="row">Status</th>
                <td>
                  <el-tag type="warning"
                    ><p style="font-size: 15px">
                      {{ formatStatusText(request.status) }}
                    </p></el-tag
                  >
                </td>
              </tr>
              <tr v-if="request.created_at">
                <th scope="row">Created At</th>
                <td>{{ request.created_at }}</td>
              </tr>
              <tr v-if="request.assigned_at">
                <th scope="row">Assigned At</th>
                <td>{{ request.assigned_at }}</td>
              </tr>
              <tr v-if="request.errored_at">
                <th scope="row">Assigned At</th>
                <td>{{ request.errored_at }}</td>
              </tr>
              <tr v-if="request.processing_at">
                <th scope="row">Processing At</th>
                <td>{{ request.processing_at }}</td>
              </tr>
              <tr v-if="request.completed_at">
                <th scope="row">Completed At</th>
                <td>{{ request.completed_at }}</td>
              </tr>
              <tr>
                <th scope="row">Legal Name</th>
                <td>{{ request.data.legal_name }}</td>
              </tr>
              <tr>
                <th scope="row">TID ({{ request.data.tid_kind }})</th>
                <td>XXX-XXX-{{ request.data.tid_last_4 }}</td>
              </tr>
              <tr>
                <th scope="row">Address</th>
                <td>{{ formattedAddress }}</td>
              </tr>
              <tr v-if="request.data.years && request.data.years.length > 0">
                <th scope="row">Years</th>
                <td>{{ request.data.years.join(", ") }}</td>
              </tr>
              <tr>
                <th scope="row">Type of Tax Information</th>
                <td>{{ request.data.type_of_tax_information }}</td>
              </tr>
              <tr>
                <th scope="row">Tax Form Numbers</th>
                <td>{{ request.data.tax_form_numbers }}</td>
              </tr>
            </tbody>
          </table>
          <br />

          <br />

          <div v-if="allowedStatuses.length > 0">
            <h5>Update Request Status</h5>
            <br />

            <el-select
              v-model="selectedStatus"
              placeholder="Select status"
              @change="changeSelectedStatus"
              style="width: 240px"
            >
              <el-option
                v-for="status in allowedStatuses"
                :key="status.value"
                :label="status.label"
                :value="status.value"
              ></el-option>
            </el-select>
            <p>The new status will be applied once you submit the form.</p>
          </div>

          <br />

          <br />
          <div v-if="selectedStatus">
            <h5>Note</h5>
            <p>Add a note associated with the status change.</p>
            <el-input
              type="textarea"
              :rows="4"
              placeholder="Please input your message"
              v-model="note"
            >
            </el-input>
          </div>

          <div v-if="selectedStatus === 'COMPLETED'">
            <br />
            <h5>IRS Transcripts</h5>
            <p>Upload the IRS Transcript in HTML format</p>
            <el-upload
              class="upload-demo"
              drag
              :action="uploadURL"
              multiple
              :before-upload="handleBeforeUpload"
              @success="handleUpload"
              @remove="handleRemoveUpload"
            >
              <i
                class="fas fa-cloud-upload"
                style="font-size: 50px; color: grey"
              ></i>
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em>
              </div>
              <template #tip>
                <div class="el-upload__tip">html files only</div>
              </template>
            </el-upload>
          </div>

          <div v-if="selectedStatus && allowedStatuses.length > 0">
            <br />
            <button type="submit" @click="submitForm" class="btn btn-secondary">
              Submit
            </button>
          </div>
        </div>

        <div
          class="col-md-6"
          v-if="
            !['COMPLETED', 'PROCESSING_ERROR', 'EXPIRED'].includes(
              request.status,
            )
          "
        >
          <button @click="downloadForm8821" class="btn btn-secondary mt-3">
            Form 8821 <i class="fas fa-download"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import apolloClient from "@/services/apollo-client";
import {
  TAX_RECORD_REQUEST_QUERY,
  UPDATE_TAX_RECORD_REQUEST_STATUS_MUTATION,
} from "@/graphql/tax-record-requests.js";
import { taxRecordRequestMixin } from "@/mixins/tax-record-request.js";
import { GENERATE_UPLOAD_HTML_TRANSCRIPT_TOKEN_FOR_VERIFIER } from "@/graphql/verifiers.js";
import { ElNotification } from "element-plus";

export default {
  components: {},
  setup() {
    const loading = ref(true); // Initialize loading state
    const route = useRoute(); // Use the useRoute hook to access the current route
    const { formatStatusText } = taxRecordRequestMixin();

    const selectedStatus = ref("");
    const note = ref("");
    const uploadURL = ref("");
    const uploadedFileIds = ref([]);

    const request = ref({
      // Initialize properties to avoid undefined access errors
      status: "",
      assigned_at: "",
      errored_at: "",
      completed_at: "",
      created_at: "",
      updated_at: "",
      batch_id: "",
      customer_id: "",
      form_8821_url: "",
      data: {
        legal_name: "",
        tid_last_4: "",
        tid_kind: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        zip_code: "",
        country: "",
        years: [], // Initialize as an empty array
        type_of_tax_information: "",
        tax_form_numbers: "",
      },
    });

    const allowedStatuses = computed(() => {
      switch (request.value.status) {
        case "ASSIGNED_TO_VERIFIER":
          return [
            { label: "Declined by Verifier", value: "DECLINED_BY_VERIFIER" },
            {
              label: "Verifier Reporting Error",
              value: "VERIFIER_REPORTING_ERROR",
            },
            { label: "Processing", value: "PROCESSING" },
          ];
        case "PROCESSING":
          return [
            { label: "Processing Error", value: "PROCESSING_ERROR" },
            { label: "Completed", value: "COMPLETED" },
          ];
        case "COMPLETED":
          return [{ label: "Expired", value: "EXPIRED" }];
        default:
          return []; // Return an empty array for statuses that do not allow changes
      }
    });

    const changeSelectedStatus = (newStatus) => {
      console.log(newStatus);
      selectedStatus.value = newStatus;
    };

    const downloadForm8821 = () => {
      if (request.value.form_8821_url) {
        window.location.href = request.value.form_8821_url;
      } else {
        console.log("Form 8821 URL is not available.");
      }
    };

    const fetchRequest = async (id) => {
      loading.value = true; // Start loading

      try {
        const { data } = await apolloClient.query({
          query: TAX_RECORD_REQUEST_QUERY,
          variables: { id },
          fetchPolicy: "network-only",
        });

        request.value = data.taxRecordRequestById; // Assuming data.taxRecordRequestById contains the request details

        const response = await apolloClient.query({
          query: GENERATE_UPLOAD_HTML_TRANSCRIPT_TOKEN_FOR_VERIFIER,
        });

        uploadURL.value = `${process.env.VUE_APP_API_URL}v1/verifiers/transcripts/upload/html?token=${response.data.generateUploadHTMLTranscriptTokenForVerifier}`;
      } catch (error) {
        console.error(error); // Handle error state
      } finally {
        loading.value = false; // End loading
      }
    };

    const submitForm = async () => {
      loading.value = true; // Start loading

      try {
        const { data } = await apolloClient.mutate({
          mutation: UPDATE_TAX_RECORD_REQUEST_STATUS_MUTATION,
          variables: {
            input: {
              id: route.params.id,
              status: selectedStatus.value,
              note: note.value,
              html_transcript_ids: uploadedFileIds.value,
            },
          },
          fetchPolicy: "network-only",
        });

        console.log(data);

        fetchRequest(route.params.id);

        selectedStatus.value = "";
        note.value = "";
        uploadedFileIds.value = [];

        ElNotification({
          title: "Success",
          message: "This update was successful",
          type: "success",
        });
      } catch (error) {
        console.log("======== ERROR ========");
        console.error(error); // Handle error state

        ElNotification({
          title: "Error",
          message: "An unexpected error occurred",
          type: "error",
        });
      } finally {
        loading.value = false; // End loading
      }
    };

    const handleBeforeUpload = (file) => {
      console.log("========= BEFORE UPLOAD =======");
      const isHTML = file.type === "text/html";
      if (!isHTML) {
        this.$message.error("You can only upload HTML files.");
      }
      return isHTML;
    };

    const handleUpload = (response, file, fileList) => {
      // Here you can handle the response from the server, for example:
      // - Display a success message
      // - Update the UI to reflect the newly uploaded file
      // - Perform additional actions based on the server's response

      console.log(file, fileList)

      // Assuming 'response' contains the server response in the given format
      if (response.data && response.data.id) {
        console.log("ADDING ID");
        console.log(response.data.id);
        uploadedFileIds.value.push(response.data.id);
      }
    };

    const handleRemoveUpload = (file, fileList) => {
      // Assume the file object or response associated with the file
      // includes the ID of the uploaded file in a property, for example, `response.fileId`.
      // This may require adjusting based on how your file object structures the ID.
      console.log(file, fileList)

      if (typeof file.response === "undefined" || !file.response.data) {
        return;
      }
      // Find the index of the file ID in the uploadedFileIds array
      const index = uploadedFileIds.value.findIndex(
        (id) => id === file.response.data.id,
      );

      if (index !== -1) {
        // Remove the file ID from the uploadedFileIds array if found
        uploadedFileIds.value.splice(index, 1);
      }
    };

    onMounted(() => {
      fetchRequest(route.params.id);
    });

    const formattedAddress = computed(() => {
      const parts = [
        request.value.data.address_line_1,
        request.value.data.address_line_2,
        request.value.data.city,
        request.value.data.state,
        request.value.data.zip_code,
      ].filter(Boolean); // Remove falsy values (empty, null, undefined)

      return parts.join(", ").replace(/, ,/g, ", "); // Remove any double commas
    });

    return {
      request,
      changeSelectedStatus,
      downloadForm8821,
      formatStatusText,
      submitForm,
      note,
      formattedAddress,
      allowedStatuses,
      selectedStatus,
      uploadURL,
      handleBeforeUpload,
      handleUpload,
      handleRemoveUpload,
    };
  },
};
</script>

<style scoped>
.request-details p {
  margin: 0.5em 0;
}
</style>
