<template>
  <div class="text-center" style="background-color: #f8f9fa; min-height: 100vh">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-5">
          <div class="p-4 bg-white shadow-sm rounded" style="margin-top: 200px">
            <Logo width="250px" />
            <form @submit.prevent="loginUser">
              <!-- Email Field -->
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  v-model="email"
                  placeholder="Email"
                  required
                />
              </div>
              <!-- Password Field -->
              <div class="mb-3">
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  v-model="password"
                  placeholder="Password"
                  required
                />
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <!-- Submit Button on the left -->
                <div>
                  <button type="submit" class="btn btn-secondary">Login</button>
                </div>
                <!-- Forgot Password Link on the far right -->
                <div>
                  <router-link to="/forgot-password" class="btn btn-link"
                    >Forgot Password?</router-link
                  >
                  &nbsp;|&nbsp;<router-link to="/register" class="btn btn-link"
                    >Register</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Logo from "@/components/Logo.vue";
import apolloClient from "@/services/apollo-client";
import { LOGIN_MUTATION } from "@/graphql/auth"; // Ensure this is correctly imported
import { setAccessToken, setRefreshToken } from "@/utils/token"; // Assuming these are correctly implemented
import authentication from "@/services/authentication"; // Assuming this utility is implemented for token refresh
import { ElNotification } from "element-plus";

export default {
  components: {
    Logo,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const email = ref("");
    const password = ref("");

    const loginUser = async () => {
      if (!store) {
        console.error("Store is undefined");
        return;
      }

      try {
        console.log("login user");
        // Assuming LOGIN_MUTATION is correctly defined and apolloClient is set up
        const { data } = await apolloClient.mutate({
          mutation: LOGIN_MUTATION,
          variables: {
            input: {
              username: email.value,
              password: password.value,
            },
          },
        });

        console.log("storing user tokens to local storage");
        // Assuming setAccessToken and setRefreshToken functions are correctly implemented
        setAccessToken(data.login.access_token);
        setRefreshToken(data.login.refresh_token);

        console.log("storing user to local storage");
        // Assuming the store and 'user/setUser' action are correctly set up
        await store.dispatch("user/setUser", data.login.user);

        console.log("initializing token refresh");
        // Assuming authentication utility is correctly implemented for token refresh
        authentication.initializeTokenRefresh();

        // Redirect to dashboard
        router.push("/dashboard");
      } catch (error) {
        ElNotification({
          title: "Authentication Failed",
          message:
            "The credentials you entered cannot be verified. Please try again.",
          type: "error",
        });

        console.error(error);
      }
    };

    return {
      email,
      password,
      loginUser,
    };
  },
};
</script>
