<template>
  <div>
    <SpeedInsights />
    <component :is="currentLayout">
      <!-- This will dynamically switch between 'DefaultLayout' and 'SidebarLayout' -->
    </component>
  </div>
</template>

<script>
import DefaultLayout from "./layouts/DefaultLayout.vue";
import SidebarLayout from "./layouts/SidebarLayout.vue";
import { SpeedInsights } from "@vercel/speed-insights/vue";

export default {
  name: "App",
  data() {
    return {
      currentLayout: "DefaultLayout",
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // Determine the layout based on the current route's meta field
        this.currentLayout =
          to.meta.layout === "sidebar" ? SidebarLayout : DefaultLayout;
      },
    },
  },
  components: {
    DefaultLayout,
    SidebarLayout,
    SpeedInsights,
  },
};
</script>
