import { gql } from "graphql-tag";
import { AUTH_USER_FIELDS_FRAGMENT } from "./auth-user";

export const LOGIN_MUTATION = gql`
  ${AUTH_USER_FIELDS_FRAGMENT}
  mutation LoginUser($input: LoginInput!) {
    login(input: $input) {
      access_token
      refresh_token
      expires_in
      token_type
      user {
        ...AuthUserFieldsFragment
      }
    }
  }
`;

export const REGISTER_MUTATION = gql`
  mutation Register($input: SignUpInput!) {
    register(input: $input) {
      success
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      status
      message
    }
  }
`;

export const UPDATE_FORGOTTEN_PASSWORD_MUTATION = gql`
  mutation UpdateForgottenPassword($input: NewPasswordWithCodeInput!) {
    updateForgottenPassword(input: $input) {
      status
      message
    }
  }
`;

export const CHECK_EMAIL_VERIFICATION_STATUS_QUERY = gql`
  query CheckEmailVerificationStatus($token: String!) {
    checkEmailVerificationStatus(token: $token) {
      token_exists
      is_email_verified
    }
  }
`;

export const VERIFY_EMAIL_MUTATION = gql`
  mutation VerifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      success
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  ${AUTH_USER_FIELDS_FRAGMENT}
  mutation RefreshToken($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      access_token
      refresh_token
      expires_in
      token_type
      user {
        ...AuthUserFieldsFragment
      }
    }
  }
`;

export const GENERATE_SCOPED_ACCESS_TOKEN_MUTATION = gql`
  mutation GenerateScopedAccessTokenMutation($scopes: [String!]!) {
    generateScopedAccessToken(scopes: $scopes) {
      token
    }
  }
`;
