import { gql } from "graphql-tag";

export const GENERATE_DOWNLOAD_FORM_8821_TOKEN_FOR_VERIFIER = gql`
  query GenerateDownloadForm8821TokenForVerifier {
    generateDownloadForm8821TokenForVerifier
  }
`;

export const GENERATE_UPLOAD_HTML_TRANSCRIPT_TOKEN_FOR_VERIFIER = gql`
  query GenerateUploadHTMLTranscriptTokenForVerifier {
    generateUploadHTMLTranscriptTokenForVerifier
  }
`;

export const AUTH_VERIFIER_QUERY = gql`
  query GetAuthVerifier {
    authVerifier {
      user_id
      status
      phone_number
      fax_number
      caf_number
      ptin_number
      address {
        address_line_1
        address_line_2
        city
        state
        zip_code
      }
    }
  }
`;

export const UPDATE_AUTH_VERIFIER_MUTATION = gql`
  mutation UpdateAuthVerifier($input: UpdateAuthVerifierInput!) {
    updateAuthVerifier(input: $input) {
      success
    }
  }
`;

export const UPSERT_AUTH_VERIFIER_ADDRESS_MUTATION = gql`
  mutation UpsertAuthVerifierAddress($input: UpsertAddressInput!) {
    upsertAuthVerifierAddress(input: $input) {
      success
    }
  }
`;

export const REGISTER_VERIFIER_MUTATION = gql`
  mutation RegisterVerifier($input: RegisterVerifierInput!) {
    registerVerifier(input: $input) {
      success
    }
  }
`;

export const AUTH_VERIFIER_STATUS_QUERY = gql`
  query AuthVerifierStatus {
    authVerifierStatus
  }
`;

export const UPDATE_AUTH_VERIFIER_STATUS_MUTATION = gql`
  mutation UpdateAuthVerifierStatus($input: UpdateAuthVerifierStatusInput!) {
    updateAuthVerifierStatus(input: $input) {
      success
    }
  }
`;
