import { createRouter, createWebHistory } from "vue-router";
import { getAccessToken } from "@/utils/token";

import LoginPage from "../views/LoginPage.vue";
import RegisterPage from "../views/RegisterPage.vue";
import ForgotPasswordPage from "../views/ForgotPasswordPage.vue";
import ResetPasswordPage from "../views/ResetPasswordPage.vue";
import VerifyEmailPage from "../views/VerifyEmailPage.vue";
import DashboardPage from "../views/DashboardPage.vue";
import TaxRecordRequestsPage from "../views/TaxRecordRequestsPage.vue";
import TaxRecordRequestPage from "../views/TaxRecordRequestPage.vue";
import AccountPage from "../views/AccountPage.vue";
import SettingsPage from "../views/SettingsPage.vue";

const routes = [
  {
    name: "LoginPage",
    path: "/login",
    component: LoginPage,
    meta: { layout: "default" },
  },
  {
    name: "RegisterPage",
    path: "/register",
    component: RegisterPage,
    meta: { layout: "default" },
  },
  {
    name: "ForgotPasswordPage",
    path: "/forgot-password",
    component: ForgotPasswordPage,
    meta: { layout: "default" },
  },
  {
    name: "ResetPasswordPage",
    path: "/reset-password/:token",
    component: ResetPasswordPage,
    meta: { layout: "default" },
  },
  {
    name: "VerifyEmailPage",
    path: "/email/verify/:userId/:token",
    component: VerifyEmailPage,
    meta: { layout: "default" },
  },

  {
    name: "HomePage",
    path: "/",
    component: DashboardPage,
    meta: { layout: "sidebar", authRequired: true },
  },
  {
    name: "DashboardPage",
    path: "/dashboard",
    component: DashboardPage,
    meta: { authRequired: true, layout: "sidebar" },
  },
  {
    name: "AccountPage",
    path: "/account",
    component: AccountPage,
    meta: { authRequired: true, layout: "sidebar" },
  },
  {
    name: "SettingsPage",
    path: "/settings",
    component: SettingsPage,
    meta: { authRequired: true, layout: "sidebar" },
  },
  {
    name: "TaxRecordRequestsPage",
    path: "/requests/:status",
    component: TaxRecordRequestsPage,
    meta: { lauthRequired: true, layout: "sidebar" },
  },
  {
    name: "TaxRecordRequestPage",
    path: "/request/:id",
    component: TaxRecordRequestPage,
    meta: { authRequired: true, layout: "sidebar" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Global navigation guard
router.beforeEach((to, from, next) => {
  if (to.meta.authRequired) {
    // Check if the access token exists in local storage
    const accessToken = getAccessToken();

    if (accessToken) {
      // Proceed to the protected route
      next();
    } else {
      // Redirect to the login page or any other appropriate action
      next("/login");
    }
  } else {
    // No authentication required, proceed to the route
    next();
  }
});

export default router;
