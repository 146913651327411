<template>
  <el-form :model="form" :rules="rules" ref="formRef" label-position="top">
    <el-form-item label="First Name" prop="first_name">
      <el-input v-model="form.first_name" placeholder="First Name"></el-input>
    </el-form-item>
    <el-form-item label="Last Name" prop="last_name">
      <el-input v-model="form.last_name" placeholder="Last Name"></el-input>
    </el-form-item>
    <el-form-item label="Email" prop="email">
      <el-input v-model="form.email" placeholder="Email"></el-input>
    </el-form-item>
    <br />
    <el-form-item>
      <br />
      <el-button type="primary" @click="submitForm">Update</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { ref, watchEffect } from "vue"; // Make sure to import ref
import { UPDATE_AUTH_USER_MUTATION } from "@/graphql/auth-user.js";
import apolloClient from "@/services/apollo-client";
import { ElNotification } from "element-plus";

export default {
  props: ["user"],
  setup(props) {
    const loading = ref(false);

    const formRef = ref(null);

    const form = ref({
      first_name: "",
      last_name: "",
      email: "",
    });

    const rules = {
      first_name: [
        {
          required: true,
          message: "Please input your first name",
          trigger: "blur",
        },
      ],
      last_name: [
        {
          required: true,
          message: "Please input your last name",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "Please input your email address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input a valid email address",
          trigger: "blur",
        },
      ],
    };

    const submitForm = async () => {
      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true; // Start loading

          try {
            const { data } = await apolloClient.mutate({
              mutation: UPDATE_AUTH_USER_MUTATION,
              variables: {
                input: form.value,
              },
              fetchPolicy: "network-only",
            });

            console.log(data);

            ElNotification({
              title: "Success",
              message: "This update was successful",
              type: "success",
            });
          } catch (error) {
            console.error(error); // Handle error state

            ElNotification({
              title: "Error",
              message: "An unexpected error occurred",
              type: "error",
            });
          } finally {
            loading.value = false; // End loading
          }
        } else {
          console.error("Validation failed for Personal Info");
        }
      });
    };

    watchEffect(() => {
      if (props.user) {
        form.value.first_name = props.user.first_name || "";
        form.value.last_name = props.user.last_name || "";
        form.value.email = props.user.email || "";
      }
    });

    return {
      rules,
      formRef,
      form,
      submitForm,
    };
  },
};
</script>
