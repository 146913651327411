import { gql } from "graphql-tag";

export const TAX_RECORD_REQUESTS_QUERY = gql`
  query TaxRecordRequests($status: String!, $page: Int) {
    verifierTaxRecordRequests(status: $status, page: $page) {
      data {
        id
        status
        user_id
        taxpayer_id
        record_id
        form_8821_url
        data {
          legal_name
          tid_last_4
          tid_kind
          address_line_1
          address_line_2
          city
          state
          zip_code
          signature_id
          signature_created_at
          credit_application_id
          name
          years
          type_of_tax_information
          tax_form_numbers
          taxpayer_email
        }
        verifier_id
        assigned_at
        errored_at
        completed_at
        created_at
        updated_at
        batch_id
        customer_id
      }
      paginator_info {
        total
        per_page
        current_page
      }
    }
  }
`;

export const TAX_RECORD_REQUEST_QUERY = gql`
  query TaxRecordRequest($id: ID!) {
    taxRecordRequestById(id: $id) {
      id
      status
      user_id
      batch_id
      customer_id
      taxpayer_id
      record_id
      verifier_id
      assigned_at
      errored_at
      completed_at
      created_at
      updated_at
      batch_id
      customer_id
      form_8821_url
      data {
        legal_name
        tid_last_4
        tid_kind
        address_line_1
        address_line_2
        city
        state
        zip_code
        signature_id
        signature_created_at
        credit_application_id
        name
        years
        type_of_tax_information
        tax_form_numbers
        taxpayer_email
      }
    }
  }
`;

export const UPDATE_TAX_RECORD_REQUEST_STATUS_MUTATION = gql`
  mutation UpdateTaxRecordRequestStatus(
    $input: UpdateTaxRecordRequestStatusInput!
  ) {
    updateTaxRecordRequestStatus(input: $input) {
      success
    }
  }
`;
