import { gql } from "graphql-tag";

export const VERIFIER_SETTINGS_QUERY = gql`
  query VerifierSettings {
    verifierSettings {
      key
      value
    }
  }
`;

export const ALLOWED_VERIFIER_SETTINGS_QUERY = gql`
  query AllowedVerifierSettings($key: String!) {
    allowedVerifierSettings(key: $key)
  }
`;

export const UPDATE_VERIFIER_SETTINGS_MUTATION = gql`
  mutation UpdateVerifierSettings($input: UpdateVerifierSettingsInput!) {
    updateVerifierSettings(input: $input) {
      success
    }
  }
`;
