import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client/core";
// import { createUploadLink } from 'apollo-upload-client';
import { setContext } from "@apollo/client/link/context";
import { getAccessToken } from "@/utils/token";

// const uploadLink = createUploadLink({
//   uri: process.env.VUE_APP_API_URL + 'graphql',
//   // credentials: 'include', // Set to 'include' if your server expects credentials (cookies, headers, etc.)
// });

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_API_URL + "graphql",
});

// create the middleware
const authLink = setContext((_, { headers }) => {
  // get the token from local storage
  const token = getAccessToken();

  // return the headers with the token
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const apolloClient = new ApolloClient({
  // link: ApolloLink.from([authLink, uploadLink]), // chain the middleware to the link
  link: ApolloLink.from([authLink, httpLink]), // chain the middleware to the link
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
    mutate: {
      fetchPolicy: "no-cache",
    },
  },
});

export default apolloClient;
