export function taxRecordRequestMixin() {
  function formatStatusText(statusKey) {
    let lowerCaseStatus = statusKey.toLowerCase().replace(/_/g, " ");
    let formattedStatus = lowerCaseStatus
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return formattedStatus;
  }

  return {
    formatStatusText,
  };
}
