<template>
  <div class="sidebar-container container-fluid h-100">
    <div class="row flex-grow-1">
      <div class="col-md-2">
        <div
          class="bg-light border-right"
          id="sidebar-wrapper"
          style="height: 100%; min-height: 100vh"
        >
          <center><Logo width="150px" /></center>
          <div class="list-group list-group-flush">
            <router-link
              to="/dashboard"
              class="list-group-item list-group-item-action bg-light"
              >Dashboard</router-link
            >

            <!-- Toggle Button for Submenu -->
            <button
              class="list-group-item list-group-item-action bg-light"
              @click="toggleSubmenu"
            >
              Requests
            </button>
            <!-- Submenu, shown based on submenuVisible state -->
            <div v-if="submenuVisible">
              <ul class="list-group bg-light">
                <li class="list-group-item pl-5">
                  <router-link
                    to="/requests/assigned"
                    class="list-group-item-action"
                    >Assigned</router-link
                  >
                </li>
                <li class="list-group-item pl-5">
                  <router-link
                    to="/requests/declined"
                    class="list-group-item-action"
                    >Declined</router-link
                  >
                </li>
                <li class="list-group-item pl-5">
                  <router-link
                    to="/requests/processing-error"
                    class="list-group-item-action"
                    >Processing Error</router-link
                  >
                </li>
                <li class="list-group-item pl-5">
                  <router-link
                    to="/requests/processing"
                    class="list-group-item-action"
                    >Processing</router-link
                  >
                </li>
                <li class="list-group-item pl-5">
                  <router-link
                    to="/requests/completed"
                    class="list-group-item-action"
                    >Completed</router-link
                  >
                </li>
              </ul>
            </div>
            <br />
            <router-link
              to="/account"
              class="list-group-item list-group-item-action bg-light"
              >Account</router-link
            >
            <router-link
              to="/settings"
              class="list-group-item list-group-item-action bg-light"
              >Settings</router-link
            >
            <a
              href="#"
              @click="logout"
              class="list-group-item list-group-item-action bg-light"
              >Logout</a
            >
            <!-- Add more links as needed -->
          </div>
        </div>
      </div>
      <!-- Main Content -->
      <div class="col-md-10">
        <div class="container mt-5" style="margin: 0px">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";
import { logoutMixin } from "@/mixins/logout.js";

export default {
  components: {
    Logo,
  },
  mixins: [logoutMixin],
  data() {
    return {
      submenuVisible: false, // Controls the visibility of the submenu
    };
  },
  methods: {
    toggleSubmenu() {
      this.submenuVisible = !this.submenuVisible; // Toggle the visibility state
    },
  },
};
</script>

<style>
.sidebar-container {
  height: 100vh; /* Make the container take full viewport height */
  display: flex;
  flex-direction: column; /* Stack children vertically */
}

.row {
  flex-grow: 1; /* Allow the row to fill the space */
  margin-right: 0; /* Adjustments if necessary */
  margin-left: 0; /* Adjustments if necessary */
}

.col-md-3 {
  padding-right: 0; /* Adjustments if necessary */
  padding-left: 0; /* Adjustments if necessary */
}

#sidebar-wrapper {
  height: 100%; /* Make the sidebar take full height of its parent */
  display: flex;
  flex-direction: column; /* Stack sidebar content vertically */
}
</style>
