<template>
  <img
    src="https://camo.githubusercontent.com/559260570a3f2f2e4a56e7e4644446d0307b1d120fe0d5a2fb2077c12a94f802/68747470733a2f2f6173736574732d676c6f62616c2e776562736974652d66696c65732e636f6d2f3631643839366436653835613761333561633835343763632f3631643839613565343030353733393961653135366265635f6c6f676f2e737667"
    alt="Logo"
    :style="{ width: width, height: 'auto' }"
    class="pb-5 pt-4"
  />
</template>

<script>
export default {
  name: "LogoImage",
  props: {
    width: {
      type: String,
      default: "150px",
    },
  },
};
</script>
