const userFromStorage = localStorage.getItem("user");

const state = {
  user: userFromStorage ? JSON.parse(userFromStorage) : null,
};

const mutations = {
  SET_USER(state, user) {
    state.user = user;

    // Check if 'user' is an object. If it is, convert it to a string.
    if (typeof user === "object") {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.setItem("user", user);
    }
  },
  CLEAR_USER(state) {
    state.user = null;
    localStorage.removeItem("user");
  },
};

const actions = {
  setUser({ commit }, user) {
    commit("SET_USER", user);
  },
  clearUser({ commit }) {
    commit("CLEAR_USER");
  },
};

const getters = {
  user: (state) => state.user,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
