<template>
  <el-form :model="form" :rules="rules" ref="formRef" label-position="top">
    <el-form-item label="Current Password" prop="current_password">
      <el-input
        type="password"
        v-model="form.current_password"
        placeholder="Current Password"
      ></el-input>
    </el-form-item>
    <el-form-item label="New Password" prop="password">
      <el-input
        type="password"
        v-model="form.password"
        placeholder="New Password"
      ></el-input>
    </el-form-item>
    <el-form-item label="Retype New Password" prop="password_confirmation">
      <el-input
        type="password"
        v-model="form.password_confirmation"
        placeholder="Retype New Password"
      ></el-input>
    </el-form-item>
    <br />
    <el-form-item>
      <el-button type="primary" @click="submitForm">Update</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { ref } from "vue";
import { UPDATE_AUTH_USER_PASSWORD_MUTATION } from "@/graphql/auth-user.js";
import apolloClient from "@/services/apollo-client";
import { ElNotification } from "element-plus";

export default {
  setup() {
    const loading = ref(false);
    const formRef = ref(null); // Reference to the form for validation

    const form = ref({
      current_password: "",
      password: "",
      password_confirmation: "",
    });

    // Define validation rules
    const rules = {
      current_password: [
        {
          required: true,
          message: "Please input your current password",
          trigger: "blur",
        },
      ],
      password: [
        {
          required: true,
          message: "Please input your new password",
          trigger: "blur",
        },
        {
          min: 8,
          message: "Your new password must be at least 8 characters long",
          trigger: "blur",
        },
      ],
      password_confirmation: [
        {
          required: true,
          message: "Please confirm your new password",
          trigger: "blur",
        },
        {
          validator: (rule, value, callback) => {
            if (value !== form.value.password) {
              callback(new Error("The password confirmation does not match"));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
    };

    const submitForm = () => {
      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true; // Start loading
          try {
            const { data } = await apolloClient.mutate({
              mutation: UPDATE_AUTH_USER_PASSWORD_MUTATION,
              variables: { input: form.value },
              fetchPolicy: "network-only",
            });
            console.log(data);
            ElNotification({
              title: "Success",
              message: "This update was successful",
              type: "success",
            });
          } catch (error) {
            console.error(error); // Handle error state
            ElNotification({
              title: "Error",
              message:
                "Your password could not be reset, please make sure to provide your current password, and a new password with at least 8 characters",
              type: "error",
            });
          } finally {
            loading.value = false; // End loading
          }
        } else {
          console.error("Validation failed.");
        }
      });
    };

    return {
      form,
      rules,
      formRef,
      submitForm,
    };
  },
};
</script>
