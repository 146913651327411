<template>
  <div>
    <h4>Account</h4>
    <p>Update your user account details below</p>

    <el-tabs v-model="activeTab" class="demo-tabs">
      <el-tab-pane label="Personal Info" name="personal-info">
        <personal-info-form :user="user"></personal-info-form>
      </el-tab-pane>
      <el-tab-pane label="Address Info" name="address-info">
        <address-info-form :verifier="verifier"></address-info-form>
      </el-tab-pane>
      <el-tab-pane label="Verifier Info" name="verifier-info">
        <verifier-info-form :verifier="verifier"></verifier-info-form>
      </el-tab-pane>
      <el-tab-pane label="Change Password" name="change-password">
        <password-form></password-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

import PersonalInfoForm from "@/views/Account/PersonalInfoForm.vue";
import VerifierInfoForm from "@/views/Account/VerifierInfoForm.vue";
import PasswordForm from "@/views/Account/PasswordForm.vue";
import AddressInfoForm from "@/views/Account/AddressInfoForm.vue";

import { AUTH_USER_QUERY } from "@/graphql/auth-user.js";
import { AUTH_VERIFIER_QUERY } from "@/graphql/verifiers.js";
import apolloClient from "@/services/apollo-client";

export default {
  components: {
    PersonalInfoForm,
    VerifierInfoForm,
    PasswordForm,
    AddressInfoForm,
  },
  setup() {
    const loading = ref(true); // Initialize loading state

    const user = ref({
      first_name: "",
      last_name: "",
      email: "",
    });

    const verifier = ref({
      status: "",
      phone_number: "",
      fax_number: "",
      irs_identifier: "",
      caf_number: "",
      ptin_number: "",
      function: "",
      address: {},
    });

    const activeTab = ref("personal-info");

    const fetch = async () => {
      loading.value = true; // Start loading

      try {
        const verifierResponse = await apolloClient.query({
          query: AUTH_VERIFIER_QUERY,
          fetchPolicy: "network-only",
        });

        console.log(verifierResponse);

        verifier.value = verifierResponse.data.authVerifier;

        const userResponse = await apolloClient.query({
          query: AUTH_USER_QUERY,
          fetchPolicy: "network-only",
        });
        user.value = userResponse.data.authUser;

        console.log(userResponse);
      } catch (error) {
        console.error(error); // Handle error state
      } finally {
        loading.value = false; // End loading
      }
    };

    onMounted(async () => {
      await fetch();
    });

    return {
      user,
      verifier,
      activeTab,
    };
  },
};
</script>
