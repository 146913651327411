import { removeAccessToken, removeRefreshToken } from "@/utils/token";

export const logoutMixin = {
  methods: {
    logout() {
      // Call the clearUser mutation from the store
      this.$store.dispatch("user/clearUser");

      removeAccessToken();
      removeRefreshToken();

      // Redirect to the login page
      this.$router.push("/login");
    },
  },
};
