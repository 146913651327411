import { createApp } from "vue";
import App from "./App.vue";
import router from "./router"; // Import the router
import ElementPlus from "element-plus";
import store from "./store"; // Adjust the path according to your file structure

import apolloClient from "./services/apollo-client";
import { DefaultApolloClient } from "@vue/apollo-composable";

import { vMaska } from "maska";

// import CSS
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "element-plus/dist/index.css";

// import JS
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const app = createApp(App);

app.directive("maska", vMaska);

app.use(router);
app.use(store);
app.use(ElementPlus);
app.provide(DefaultApolloClient, apolloClient);
app.mount("#app");
