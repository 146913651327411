<template>
  <div class="text-center" style="background-color: #f8f9fa; min-height: 100vh">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <!-- Adjusted for additional width -->
          <Logo width="250px" />
          <el-steps :active="activeStep" finish-status="warning" simple>
            <el-step title="Personal Info"></el-step>
            <el-step title="Address Info"></el-step>
            <el-step title="Verifier Details"></el-step>
            <el-step title="Finish"></el-step>
          </el-steps>
          <div class="p-4 bg-white shadow-sm rounded" style="margin-top: 50px">
            <div class="wizard-container">
              <div v-if="!formSubmitted" class="step-content">
                <!-- Step 1: Personal Info -->
                <div v-if="activeStep === 0">
                  <div class="section personal-info">
                    <h2 class="section-title">Personal Info</h2>
                    <el-form
                      :model="formPersonalInfo"
                      :rules="rulesPersonalInfo"
                      ref="formRefPersonalInfo"
                      label-position="top"
                      class="form-container"
                    >
                      <el-form-item
                        label="First Name"
                        prop="first_name"
                        class="form-item"
                      >
                        <el-input
                          v-model="formPersonalInfo.first_name"
                          placeholder="First Name"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="Last Name"
                        prop="last_name"
                        class="form-item"
                      >
                        <el-input
                          v-model="formPersonalInfo.last_name"
                          placeholder="Last Name"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="Email"
                        prop="email"
                        class="form-item"
                      >
                        <el-input
                          v-model="formPersonalInfo.email"
                          placeholder="Email"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="Password"
                        prop="password"
                        class="form-item"
                      >
                        <el-input
                          type="password"
                          v-model="formPersonalInfo.password"
                          placeholder="Password"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="Password Confirmation"
                        prop="password_confirmation"
                        class="form-item"
                      >
                        <el-input
                          type="password"
                          v-model="formPersonalInfo.password_confirmation"
                          placeholder="Password Confirmation"
                        ></el-input>
                      </el-form-item>
                    </el-form>
                  </div>
                  <!-- Personal Info fields -->
                  <!-- Include form fields for personal info here -->
                  <el-button @click="nextStep">Next</el-button>
                </div>
                <div v-if="activeStep === 1">
                  <div class="section address-info">
                    <h2 class="section-title">Address Info</h2>

                    <el-form
                      :model="formAddressInfo"
                      :rules="rulesAddressInfo"
                      ref="formRefAddressInfo"
                      label-position="top"
                      class="form-container"
                    >
                      <el-form-item
                        label="Address Line 1"
                        prop="address_line_1"
                      >
                        <el-input
                          v-model="formAddressInfo.address_line_1"
                          placeholder="Address Line 1"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="Address Line 2"
                        prop="address_line_2"
                      >
                        <el-input
                          v-model="formAddressInfo.address_line_2"
                          placeholder="Address Line 2"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="City" prop="city">
                        <el-input
                          v-model="formAddressInfo.city"
                          placeholder="City"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="State" prop="state">
                        <el-select
                          v-model="formAddressInfo.state"
                          placeholder="Select a state"
                          filterable
                        >
                          <el-option
                            v-for="state in states"
                            :key="state.value"
                            :label="state.label"
                            :value="state.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>

                      <el-form-item label="Zip Code" prop="zip_code">
                        <el-input
                          v-model="formAddressInfo.zip_code"
                          placeholder="Zip Code"
                        ></el-input>
                      </el-form-item>
                    </el-form>
                  </div>
                  <el-button @click="prevStep">Previous</el-button>
                  <el-button @click="nextStep">Next</el-button>
                </div>
                <!-- Step 2: Verifier Details -->
                <div v-if="activeStep === 2">
                  <div class="section verifier-details">
                    <h2 class="section-title">Verifier Details</h2>
                    <el-form
                      :model="formVerifierDetails"
                      :rules="rulesVerifierDetails"
                      ref="formRefVerifierDetails"
                      label-position="top"
                      class="form-container"
                    >
                      <el-form-item
                        label="Phone Number"
                        prop="phone_number"
                        class="form-item"
                      >
                        <el-input
                          v-model="formVerifierDetails.phone_number"
                          v-maska
                          :data-maska="phoneMask"
                          :placeholder="phoneMask"
                        ></el-input>
                      </el-form-item>

                      <el-form-item
                        class="form-item"
                        prop="fax_number"
                        label="Fax Number"
                      >
                        <el-input
                          v-model="formVerifierDetails.fax_number"
                          v-maska
                          :data-maska="phoneMask"
                          :placeholder="phoneMask"
                        ></el-input>
                      </el-form-item>

                      <el-form-item
                        class="form-item"
                        prop="caf_number"
                        label="CAF Number"
                      >
                        <el-input
                          v-model="formVerifierDetails.caf_number"
                          v-maska
                          :data-maska="cafNumberMask" 
                          :data-maska-tokens="cafNumberMaskTokens"
                        ></el-input>
                      </el-form-item>

                      <el-form-item
                        class="form-item"
                        prop="ptin_number"
                        label="PTIN Number"
                      >
                        <el-input
                          v-model="formVerifierDetails.ptin_number"
                          v-maska
                          :data-maska="ptinMask"
                          :placeholder="ptinMask"
                        ></el-input>
                      </el-form-item>

                    </el-form>
                    <!-- Add the rest of the Verifier Details fields here -->
                  </div>
                  <!-- Verifier Details fields -->
                  <!-- Include form fields for verifier details here -->
                  <el-button @click="prevStep">Previous</el-button>
                  <el-button @click="nextStep">Next</el-button>
                </div>
                <!-- Step 4: Agreement -->
                <div v-if="activeStep === 3">
                  <div class="section submit">
                    <h2 class="section-title">Register</h2>
                    <el-form
                      @submit.prevent="submitForm"
                      :model="formFinishInfo"
                      :rules="rulesFinishInfo"
                      ref="formRefFinish"
                      label-position="top"
                      class="form-container"
                    >
                      <el-form-item
                        label="Please enter the registration code that was provided to you"
                        prop="registration_code"
                        class="form-item"
                      >
                        <el-input
                          v-model="formFinishInfo.registration_code"
                          placeholder="Registration Code"
                        ></el-input>
                      </el-form-item>
                      <br />

                      <el-form-item class="form-item" prop="agree_to_terms">
                        <el-checkbox
                          v-model="formFinishInfo.agree_to_terms"
                        ></el-checkbox>
                        &nbsp; I agree to the &nbsp;<a
                          href="https://moderntax.io/terms"
                          target="_blank"
                          >Terms & Conditions</a
                        >
                        &nbsp; and the &nbsp;
                        <a
                          href="https://www.moderntax.io/privacy-policy"
                          target="_blank"
                          >Privacy Policy</a
                        >
                      </el-form-item>
                      <div style="margin-top: 40px">
                        <el-form-item class="form-item">
                          <button
                            type="submit"
                            @click.prevent="submitForm"
                            class="btn btn-secondary"
                          >
                            Submit
                          </button>
                        </el-form-item>
                      </div>
                    </el-form>
                  </div>
                  <!-- Include agreement checkbox here -->
                  <el-button @click="prevStep">Previous</el-button>
                </div>
              </div>
              <div v-else style="padding: 50px">
                <center>
                  Thanks for submitting your application. You will receive a
                  confirmation email shortly. Please verify your email address
                  in order to login
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive, nextTick} from "vue";
import Logo from "@/components/Logo.vue"; // Adjust the path as necessary
import apolloClient from "@/services/apollo-client";
import { REGISTER_VERIFIER_MUTATION } from "@/graphql/verifiers";
import { ElNotification } from "element-plus";
import { states } from "@/enums/states";

export default {
  components: {
    Logo,
  },
  setup() {
    const activeStep = ref(0);
    const formRefPersonalInfo = ref(null);
    const formRefAddressInfo = ref(null);
    const formRefVerifierDetails = ref(null);
    const formRefFinish = ref(null);

    const formPersonalInfo = reactive({
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
    });

    const formAddressInfo = reactive({
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      zip_code: "",
    });

    const formVerifierDetails = reactive({
      phone_number: "",
      fax_number: "",
      caf_number: "",
      ptin_number: "",
    });

    const formFinishInfo = reactive({
      registration_code: "",
      agree_to_terms: false,
    });

    const validatePasswordConfirmation = (rule, value, callback) => {
      if (value === formPersonalInfo.password) {
        callback();
      } else {
        callback(new Error("Password confirmation mismatch"));
      }
    };

    const validateAgreementToTerms = (rule, value, callback) => {
      if (value) {
        callback(); // value is true, so validation passes
      } else {
        callback(new Error("You must agree to the terms and conditions")); // value is false, so validation fails
      }
    };

    const rulesPersonalInfo = reactive({
      first_name: [
        {
          required: true,
          message: "Please input your first name",
          trigger: "blur",
        },
      ],
      last_name: [
        {
          required: true,
          message: "Please input your last name",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "Please input your email address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input a valid email address",
          trigger: ["blur", "change"],
        },
      ],
      password: [
        {
          required: true,
          message: "Please input your password",
          trigger: "blur",
        },
        {
          min: 8,
          message: "Please enter a passwowrd that is at least 8 characters",
          trigger: "blur",
        },
      ],
      password_confirmation: [
        {
          required: true,
          message: "Please input a valid passport confirmation",
          trigger: "blur",
        },
        { validator: validatePasswordConfirmation, trigger: "blur" },
      ],
    });

    const rulesAddressInfo = reactive({
      address_line_1: [
        {
          required: true,
          message: "Address Line 1 is required",
          trigger: "blur",
        },
      ],
      city: [{ required: true, message: "City is required", trigger: "blur" }],
      state: [
        { required: true, message: "State is required", trigger: "blur" },
      ],
      zip_code: [
        { required: true, message: "Zip Code is required", trigger: "blur" },
        {
          pattern: /^\d{5}(-\d{4})?$/,
          message: "Zip Code must be valid",
          trigger: "blur",
        },
      ],
    });

    const rulesVerifierDetails = reactive({
      phone_number: [
        {
          required: true,
          message: "Please input your phone number",
          trigger: "blur",
        },
      ],
      caf_number: [
        {
          required: true,
          message: "CAF Number is required",
          trigger: "blur",
        },
        {
          pattern: /^[a-zA-Z0-9]{10}$/,
          message: "CAF Number must be exactly 10 alphanumeric characters",
          trigger: "blur",
        },
      ],
      ptin_number: [
        {
          required: true,
          message: "PTIN Number is required",
          trigger: "blur",
        },
        {
          pattern: /^P\d{8}$/,
          message: "PTIN Number must start with 'P' followed by 8 digits",
          trigger: "blur",
        },
      ],
    });

    const rulesFinishInfo = reactive({
      registration_code: [
        {
          required: true,
          message: "Please enter the registration code",
          trigger: "blur",
        },
      ],
      agree_to_terms: [
        {
          required: true,
          message: "You must agree to the terms and conditions",
          trigger: "change",
          type: "boolean",
          validator: validateAgreementToTerms,
        },
      ],
    });

    const formSubmitted = ref(false);

    const phoneMask = ref("(###) ###-####");
    const ptinMask = ref('P########');
    const cafNumberMask = ref('**********');
    const cafNumberMaskTokens = ref({
      '*': '[A-Za-z0-9]'
    });

    const nextStep = () => {
      nextTick(() => {
        switch (activeStep.value) {
          case 0:
            formRefPersonalInfo.value.validate((valid) => {
              if (valid) {
                activeStep.value++;
              } else {
                console.error("Validation failed for Personal Info");
                // Handle validation failure (e.g., show an error message)
              }
            });
            break;
          case 1:
            formRefAddressInfo.value.validate((valid) => {
              if (valid) {
                activeStep.value++;
              } else {
                console.error("Validation failed for Address Info");
                // Handle validation failure (e.g., show an error message)
              }
            });
            break;
          case 2:
            formRefVerifierDetails.value.validate((valid) => {
              if (valid) {
                activeStep.value++;
              } else {
                console.error("Validation failed for Verifier Details");
                // Handle validation failure
              }
            });
            break;
          default:
            console.log("Unknown step");
            break;
        }
      });
    };

    const prevStep = () => {
      if (activeStep.value > 0) {
        activeStep.value--;
      }
    };

    const submitForm = async () => {
      formRefFinish.value.validate(async (valid) => {
        if (valid) {
          try {
            console.log("register user");


            console.log( {
                    address_line_1: formAddressInfo.address_line_1,
                    address_line_2: formAddressInfo.address_line_2,
                    city: formAddressInfo.city,
                    state: formAddressInfo.state,
                    zip_code: formAddressInfo.zip_code,
                  })

            const { data } = await apolloClient.mutate({
              mutation: REGISTER_VERIFIER_MUTATION,
              variables: {
                input: {
                  personal_info : {
                    first_name: formPersonalInfo.first_name,
                    last_name: formPersonalInfo.last_name,
                    email: formPersonalInfo.email,
                    password: formPersonalInfo.password,
                    password_confirmation: formPersonalInfo.password_confirmation,
                  },
                  address_info : {
                    address_line_1: formAddressInfo.address_line_1,
                    address_line_2: formAddressInfo.address_line_2,
                    city: formAddressInfo.city,
                    state: formAddressInfo.state,
                    zip_code: formAddressInfo.zip_code,
                  },
                  verifier_info : {
                    phone_number: formVerifierDetails.phone_number,
                    fax_number: formVerifierDetails.fax_number,
                    caf_number: formVerifierDetails.caf_number,
                    ptin_number: formVerifierDetails.ptin_number,
                  },
                  registration_info : {
                    registration_code: formFinishInfo.registration_code,
                    agree_to_terms: formFinishInfo.agree_to_terms,
                  }
                },
              },
            });

            console.log(data);

            if (data.registerVerifier.success === true) {
              formSubmitted.value = true;
            } else {
              ElNotification({
                title: "Error",
                message: "An error occurred while creating your account.",
                type: "error",
              });
            }
          } catch (error) {
            ElNotification({
              title: "Error",
              message: "An error occurred while creating your account.",
              type: "error",
            });

            console.log("========= ERROR =========");
            console.error(error);
          }
        }
      });
    };

    return {
      activeStep,

      formPersonalInfo,
      formAddressInfo,
      formVerifierDetails,
      formFinishInfo,

      formRefPersonalInfo,
      formRefAddressInfo,
      formRefVerifierDetails,
      formRefFinish,

      rulesPersonalInfo,
      rulesAddressInfo,
      rulesVerifierDetails,
      rulesFinishInfo,

      formSubmitted,

      phoneMask,
      ptinMask,

      cafNumberMask,
      cafNumberMaskTokens,

      states,

      nextStep,
      prevStep,
      submitForm,
    };
  },
};
</script>
<style scoped>
.wizard-container {
  /* max-width: 600px; */
  margin: auto;
}
.step-content {
  margin-top: 20px;
}
.form-container {
  max-width: 600px;
  margin: auto;
}
.section {
  margin-bottom: 2rem;
  padding: 1rem;
  border-radius: 8px;
}
.section-title {
  margin-bottom: 1rem;
  color: #333;
  font-size: 1.5rem;
}
.form-item {
  margin-bottom: 1rem;
}
</style>
