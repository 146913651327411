<template>
  <el-form :model="form" :rules="rules" ref="formRef" label-position="top">
    <el-form-item label="Phone Number" prop="phone_number">
      <el-input
        v-model="form.phone_number"
        v-maska
        :data-maska="phoneMask"
        :placeholder="phoneMask"
      ></el-input>
    </el-form-item>
    <el-form-item label="Fax Number" prop="fax_number">
      <el-input
        v-model="form.fax_number"
        v-maska
        :data-maska="phoneMask"
        :placeholder="phoneMask"
      ></el-input>
    </el-form-item>

    <el-form-item label="CAF Number" prop="caf_number">
      <el-input 
          v-model="form.caf_number" 
          v-maska
          :data-maska="cafNumberMask" 
          :data-maska-tokens="cafNumberMaskTokens"          
          
          ></el-input>
    </el-form-item>

    <el-form-item label="PTIN Number" prop="ptin_number">
      <el-input 
          v-model="form.ptin_number"
          v-maska
          :data-maska="ptinMask"
          :placeholder="ptinMask"
          >
      </el-input>
    </el-form-item>
    <el-form-item>
      <br />
      <el-button type="primary" @click="submitForm">Update</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { ref, watchEffect } from "vue";
import { UPDATE_AUTH_VERIFIER_MUTATION } from "@/graphql/verifiers.js";
import apolloClient from "@/services/apollo-client";
import { ElNotification } from "element-plus";

export default {
  props: ["verifier"],
  setup(props) {
    const loading = ref(false);
    const formRef = ref(null);

    const form = ref({
      phone_number: "",
      fax_number: "",
      caf_number: "",
      ptin_number: "",
    });

    const phoneMask = ref("(###) ###-####");

    const ptinMask = ref('P########');
    const cafNumberMask = ref('**********');
    const cafNumberMaskTokens = ref({
      '*': '[A-Za-z0-9]'
    });


    const rules = {
      phone_number: [
        {
          required: true,
          message: "Phone Number is required",
          trigger: "blur",
        },
      ],
      fax_number: [{ required: false }],
      caf_number: [
        {
          required: true,
          message: "CAF Number is required",
          trigger: "blur",
        },
        {
          pattern: /^[a-zA-Z0-9]{10}$/,
          message: "CAF Number must be exactly 10 alphanumeric characters",
          trigger: "blur",
        },
      ],
      ptin_number: [
        {
          required: true,
          message: "PTIN Number is required",
          trigger: "blur",
        },
        {
          pattern: /^P\d{8}$/,
          message: "PTIN Number must start with 'P' followed by 8 digits",
          trigger: "blur",
        },
      ],
    };

    const submitForm = () => {
      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          try {
            const { data } = await apolloClient.mutate({
              mutation: UPDATE_AUTH_VERIFIER_MUTATION,
              variables: { input: form.value },
              fetchPolicy: "network-only",
            });

            console.log(data);
            ElNotification({
              title: "Success",
              message: "This update was successful",
              type: "success",
            });
          } catch (error) {
            console.error(error);
            ElNotification({
              title: "Error",
              message: "An unexpected error occurred",
              type: "error",
            });
          } finally {
            loading.value = false;
          }
        } else {
          console.log("Validation failed.");
        }
      });
    };

    watchEffect(() => {
      if (props.verifier) {
        form.value.phone_number = props.verifier.phone_number || "";
        form.value.fax_number = props.verifier.fax_number || "";
        form.value.caf_number = props.verifier.caf_number || "";
        form.value.ptin_number = props.verifier.ptin_number || "";
      }
    });

    return {
      form,
      rules,
      formRef,
      phoneMask,
      ptinMask,

      cafNumberMask,
      cafNumberMaskTokens,
      submitForm,
    };
  },
};
</script>
