<template>
  <div class="text-center" style="background-color: #f8f9fa; min-height: 100vh">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-5">
          <div
            class="p-4 bg-white shadow-sm rounded"
            style="margin-top: 200px; background: red"
          >
            <Logo width="250px" />
            <form @submit.prevent="forgotPassword">
              <div class="mb-4" style="text-align: left">
                <p>
                  Please enter your email address below to reset password link
                </p>
              </div>

              <!-- Email Field -->
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  v-model="email"
                  placeholder="Email"
                  required
                />
              </div>

              <!-- Submit Button -->
              <div class="text-center">
                <button type="submit" class="btn btn-secondary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Logo from "@/components/Logo.vue";
import { FORGOT_PASSWORD_MUTATION } from "@/graphql/auth.js";
import apolloClient from "@/services/apollo-client";
import { ElNotification } from "element-plus";

export default {
  components: {
    Logo,
  },
  setup() {
    const email = ref("");

    const forgotPassword = async () => {
      try {
        await apolloClient.mutate({
          mutation: FORGOT_PASSWORD_MUTATION,
          variables: {
            input: {
              email: email.value,
            },
          },
        });

        // Show a success notification
        ElNotification({
          title: "Success",
          message: "A reset password link has been sent to your email address.",
          type: "success",
        });

        // Reset the form field
        email.value = "";
      } catch (error) {
        console.error("Forgot password error", error);
      }
    };

    // Return the reactive data and functions to use in the template
    return {
      email,
      forgotPassword, // Make sure to return this so it can be used in the template
    };
  },
};
</script>
