<template>
  <el-form
    :model="addressForm"
    :rules="rules"
    ref="addressFormRef"
    label-position="top"
  >
    <el-form-item label="Address Line 1" prop="address_line_1">
      <el-input
        v-model="addressForm.address_line_1"
        placeholder="Address Line 1"
      ></el-input>
    </el-form-item>
    <el-form-item label="Address Line 2" prop="address_line_2">
      <el-input
        v-model="addressForm.address_line_2"
        placeholder="Address Line 2"
      ></el-input>
    </el-form-item>
    <el-form-item label="City" prop="city">
      <el-input v-model="addressForm.city" placeholder="City"></el-input>
    </el-form-item>
    <el-form-item label="State" prop="state">
      <el-select
        v-model="addressForm.state"
        placeholder="Select a state"
        filterable
      >
        <el-option
          v-for="state in states"
          :key="state.value"
          :label="state.label"
          :value="state.value"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Zip Code" prop="zip_code">
      <el-input
        v-model="addressForm.zip_code"
        placeholder="Zip Code"
      ></el-input>
    </el-form-item>
    <br />
    <el-form-item>
      <el-button type="primary" @click="submitForm">Submit</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { ref, watchEffect } from "vue";
import { UPSERT_AUTH_VERIFIER_ADDRESS_MUTATION } from "@/graphql/verifiers.js";
import apolloClient from "@/services/apollo-client";
import { ElNotification } from "element-plus";
import { states } from "@/enums/states";

export default {
  props: ["verifier"],
  setup(props) {
    const loading = ref(false);
    const addressFormRef = ref(null);

    const addressForm = ref({
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      zip_code: "",
    });

    const rules = {
      address_line_1: [
        {
          required: true,
          message: "Address Line 1 is required",
          trigger: "blur",
        },
      ],
      city: [{ required: true, message: "City is required", trigger: "blur" }],
      state: [
        { required: true, message: "State is required", trigger: "blur" },
      ],
      zip_code: [
        { required: true, message: "Zip Code is required", trigger: "blur" },
        {
          pattern: /^\d{5}(-\d{4})?$/,
          message: "Zip Code must be valid",
          trigger: "blur",
        },
      ],
    };

    const submitForm = () => {
      addressFormRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          try {
            const { data } = await apolloClient.mutate({
              mutation: UPSERT_AUTH_VERIFIER_ADDRESS_MUTATION,
              variables: {
                input: addressForm.value,
              },
              fetchPolicy: "network-only",
            });

            console.log(data);

            ElNotification({
              title: "Success",
              message: "This update was successful",
              type: "success",
            });
          } catch (error) {
            console.error(error);
            ElNotification({
              title: "Error",
              message: "An unexpected error occurred",
              type: "error",
            });
          } finally {
            loading.value = false;
          }
        } else {
          console.log("Validation failed.");
        }
      });
    };

    watchEffect(() => {
      if (props.verifier.address) {
        const { address } = props.verifier;
        addressForm.value = {
          address_line_1: address.address_line_1 || "",
          address_line_2: address.address_line_2 || "",
          city: address.city || "",
          state: address.state || "",
          zip_code: address.zip_code || "",
        };
      }
    });

    return {
      addressForm,
      addressFormRef,
      rules,
      states,
      submitForm,
    };
  },
};
</script>
