<template>
  <div>
    <h4>Hello {{ userFirstName }}</h4>
    <p>Welcome to your ModernTax account</p>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const userFirstName = ref("");

onMounted(() => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.first_name) {
    userFirstName.value = user.first_name;
  }
});
</script>
