export function setAccessToken(token) {
  localStorage.setItem("access_token", token);
}

export function getAccessToken() {
  return localStorage.getItem("access_token");
}

export function removeAccessToken() {
  localStorage.removeItem("access_token");
}

export function setRefreshToken(token) {
  localStorage.setItem("refresh_token", token);
}

export function getRefreshToken() {
  return localStorage.getItem("refresh_token");
}

export function removeRefreshToken() {
  localStorage.removeItem("refresh_token");
}

export function setAccessTokenExpiresAt(expires_at) {
  localStorage.setItem("access_token_expires_at", expires_at);
}

export function getAccessTokenExpiresAt() {
  return localStorage.getItem("access_token_expires_at");
}

export function removeAccessTokenExpiresAt() {
  localStorage.removeItem("access_token_expires_at");
}
