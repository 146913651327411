<template>
  <div class="text-center" style="background-color: #f8f9fa; min-height: 100vh">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="p-4 bg-white shadow-sm rounded" style="margin-top: 200px; background: red">
            <Logo width="300px" />


            <el-form :model="form" :rules="rules" ref="formRef" label-position="top" @submit.prevent="submit">
              <!-- Email Field -->
              <el-form-item label="Email" prop="email">
                <el-input v-model="form.email" placeholder="Email" type="email"></el-input>
              </el-form-item>

              <!-- New Password Field -->
              <el-form-item label="New Password" prop="password">
                <el-input v-model="form.password" type="password" placeholder="New Password" autocomplete="new-password"></el-input>
              </el-form-item>

              <!-- Confirm New Password Field -->
              <el-form-item label="Confirm New Password" prop="password_confirmation">
                <el-input v-model="form.password_confirmation" type="password" placeholder="Confirm New Password" autocomplete="new-password"></el-input>
              </el-form-item>

              <!-- Optional: Token Field (hidden or visible depending on your flow) -->
              <el-form-item label="Token" prop="token" style="display: none">
                <el-input v-model="form.token" type="text" placeholder="Token"></el-input>
              </el-form-item>

              <div style="margin-top: 40px">
                <el-form-item class="form-item">
                  <button
                    type="submit"
                    @click.prevent="submit"
                    class="btn btn-secondary"
                  >
                    Submit
                  </button>
                </el-form-item>
              </div>
            </el-form>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Logo from "@/components/Logo.vue";
import { ref, onMounted, reactive } from "vue";
import apolloClient from "@/services/apollo-client";
import { UPDATE_FORGOTTEN_PASSWORD_MUTATION } from "@/graphql/auth.js";
import { useRouter, useRoute } from "vue-router";
import { ElNotification } from "element-plus";

export default {
  components: {
    Logo,
  },
  setup() {
    const form = reactive({
      email: "",
      password: "",
      password_confirmation: "",
      token: "", // This could be obtained from URL parameters or another source
    });

    const router = useRouter();
    const route = useRoute(); // Use useRoute to access the current route
    const formRef = ref(null);

    const validatePasswordConfirmation = (rule, value, callback) => {
      if (value === form.password) {
        callback();
      } else {
        callback(new Error("Password confirmation mismatch"));
      }
    };


    const rules = reactive({
      email: [
        {
          required: true,
          message: "Please input your email address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input a valid email address",
          trigger: ["blur", "change"],
        },
      ],
      password: [
        {
          required: true,
          message: "Please input your password",
          trigger: "blur",
        },
        {
          min: 8,
          message: "Please enter a password that is at least 8 characters",
          trigger: "blur",
        },
      ],
      password_confirmation: [
        {
          required: true,
          message: "Please input a valid password confirmation",
          trigger: "blur",
        },
        { validator: validatePasswordConfirmation, trigger: "blur" },
      ],
    });

    onMounted(() => {
      if (route.query.token) {
        form.token = route.query.token; 
      }
    });

    const submit = async () => {
      formRef.value.validate(async (valid) => {
          if (!valid) {
            ElNotification({
              title: "Error",
              message: "Please correct the errors in the form",
              type: "error",
            });
            return;
          }

          if (form.password !== form.password_confirmation) {
          ElNotification({
            title: "Error",
            message: "Passwords do not match!",
            type: "error",
          });
          return;
        }

        try {
          const { data } = await apolloClient.mutate({
            mutation: UPDATE_FORGOTTEN_PASSWORD_MUTATION,
            variables: {
              input: {
                email: form.email,
                password: form.password,
                password_confirmation: form.password_confirmation,
                token: form.token,
              },
            },
          });

          console.log(data);
          ElNotification({
            title: "Success",
            message: "Your password has been successfully reset.",
            type: "success",
          });
          // Optionally navigate the user to the login page
          router.push("/login");
        } catch (error) {
          console.error("Error updating forgotten password", error);
          ElNotification({
            title: "Error",
            message: "An error occurred while resetting your password.",
            type: "error",
          });
        }



      });


    };

    return {
      form,
      rules,
      formRef,
      submit,
    };
  },
};
</script>
