// authentication.js (Authentication service)

import { reactive } from "vue";
import apolloClient from "@/services/apollo-client";
import { REFRESH_TOKEN_MUTATION } from "@/graphql/auth.js";
import {
  setAccessToken,
  setRefreshToken,
  setAccessTokenExpiresAt,
} from "@/utils/token";

const authentication = {
  // ...
  state: reactive({
    // Define the authentication state variables here (e.g., accessToken, refreshToken, etc.)
    expiresAt: null,
    // ...
  }),

  async refreshTokens() {
    try {
      const response = await apolloClient.mutate({
        mutation: REFRESH_TOKEN_MUTATION, // Replace with the actual refreshToken mutation
        variables: {
          refreshToken: this.state.refreshToken,
        },
      });

      // Update the access token, refresh token, and expiration time in the state
      this.state.accessToken = response.data.refreshToken.accessToken;
      this.state.refreshToken = response.data.refreshToken.refreshToken;
      this.state.expiresAt = response.data.refreshToken.expiresAt;

      // Update the tokens in the local storage
      setAccessToken(this.state.accessToken);
      setRefreshToken(this.state.refreshToken);
      setAccessTokenExpiresAt(this.state.expiresAt);
    } catch (error) {
      // Handle token refreshing error
      console.error("Error refreshing tokens:", error);
      throw error; // Rethrow the error to handle it in the calling code
    }
  },

  scheduleTokenRefresh() {
    // Calculate the time interval before the token expiration
    const expiresIn = this.state.expiresAt - Date.now();
    const refreshInterval = expiresIn * 0.9; // Refresh the token when it is 90% through the expiration time

    // Schedule token refreshing using a timer
    setTimeout(async () => {
      try {
        await this.refreshTokens();
        this.scheduleTokenRefresh(); // Reschedule token refreshing
      } catch (error) {
        console.error("Error refreshing tokens:", error);
      }
    }, refreshInterval);
  },

  initializeTokenRefresh() {
    // Calculate the initial refresh interval based on the current token expiration time
    const expiresIn = this.state.expiresAt - Date.now();
    if (expiresIn > 0) {
      this.scheduleTokenRefresh();
    }
  },

  // ...
};

export default authentication;
